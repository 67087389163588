import React, { useMemo, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import { createApolloClient } from "graphql/client";
import jwt_decode from "jwt-decode";
import { Login } from "components/Login";
import { Header } from "./components/Header";
import { Buildings } from "./components/Buildings";
import { BuildingAdd } from "./components/BuildingAdd";
import { BuildingEdit } from "./components/BuildingEdit";
import { Reports } from "./components/Reports";
import { ReportAdd } from "./components/ReportAdd";
import { ReportEdit } from "./components/ReportEdit";
import { Settings } from "./components/Settings";

import "./App.scss";

function App() {
    const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));

    const client = useMemo(() => {
        console.log("creating new Apollo client with token", authToken);
        return createApolloClient(authToken);
    }, [authToken]);
    console.log(client);

    if (!authToken) {
        return (
            <Login
                onAuthSuccess={(newAuthToken) => {
                    localStorage.setItem("authToken", newAuthToken);
                    setAuthToken(newAuthToken);
                }}
            />
        );
    }

    const invalidateToken = () => {
        client.resetStore();
        localStorage.removeItem("authToken");
        setAuthToken("");
    };

    const decodedToken: {
        exp: number;
        origIat: number;
        username: string;
    } = jwt_decode(authToken);

    if (new Date(decodedToken.exp * 1000) < new Date()) {
        invalidateToken();
    }
    console.log("Decoded JWT token", decodedToken);

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Layout className="layout">
                    <Header onLogout={invalidateToken} />
                    <Layout.Content className="site-layout" style={{ padding: "0 50px" }}>
                        <Routes>
                            <Route path={"/"} element={<Buildings />} />
                            <Route path={"/building/add"} element={<BuildingAdd />} />
                            <Route path={"/building/:id"} element={<BuildingEdit />} />
                            <Route path={"/reports"} element={<Reports />} />
                            <Route path={"/settings"} element={<Settings username={decodedToken.username} />} />
                            <Route path={"/building/:buildingId/report/new"} element={<ReportAdd />} />
                            <Route path={"/building/:buildingId/report/:reportId"} element={<ReportEdit />} />
                        </Routes>
                    </Layout.Content>
                    <Layout.Footer style={{ textAlign: "center" }}>Fishplant © 2021</Layout.Footer>
                </Layout>
            </BrowserRouter>
        </ApolloProvider>
    );
}

export default App;
