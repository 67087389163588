import { FileOutlined, HomeOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import React from "react";

const { Header: AntdHeader } = Layout;

interface HeaderProps {
    onLogout?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onLogout }) => {
    return (
        <AntdHeader>
            <div className="logo" />
            <Menu
                theme="dark"
                mode="horizontal"
                items={[
                    {
                        key: 1,
                        label: (
                            <Link to="/">
                                <HomeOutlined /> Buildings
                            </Link>
                        ),
                    },
                    {
                        key: 2,
                        label: (
                            <Link to="/reports">
                                <FileOutlined /> Reports
                            </Link>
                        ),
                    },
                    {
                        key: 3,
                        label: (
                            <Link to="/settings">
                                <SettingOutlined /> Settings
                            </Link>
                        ),
                    },
                    {
                        key: 4,
                        label: (
                            <a onClick={onLogout}>
                                <LogoutOutlined /> Logout
                            </a>
                        ),
                    },
                ]}
            />
        </AntdHeader>
    );
};
