import { Button, Form, Input, PageHeader, Row } from "antd";
import React, { useEffect } from "react";
import { createApolloClient } from "../graphql/client";
import { message } from "antd";
import { useLoginMutation } from "../graphql/operations";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface LoginProps {
    onAuthSuccess: (token: string) => void;
}

export const Login: React.FC<LoginProps> = ({ onAuthSuccess }) => {
    const [loginMutation, { data, loading, error }] = useLoginMutation({ client: createApolloClient("") });

    useEffect(() => {
        if (error) message.error(`${error}`, 5);
    }, [error]);

    if (data?.tokenAuth) {
        const newAuthToken = data.tokenAuth.token || "";

        if (newAuthToken) {
            onAuthSuccess(newAuthToken);
        }
    }

    const onFinish = (values: { username: string; password: string }) => {
        loginMutation({ variables: values });
    };

    return (
        <>
            <Row justify="center">
                <PageHeader title="Login" />
            </Row>
            <Row justify="center">
                <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ message: "Please input your username!", required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ message: "Please input your password!", required: true }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        </>
    );
};
