import React, { useEffect } from "react";
import { Select, message } from "antd";
import { useCalculationMethodsQuery } from "graphql/operations";

type CalculationMethodSelectorProps = {
    value?: string;
    onChange?: (value: string | undefined) => void;
};

export function CalculationMethodsSelector({ value, onChange }: CalculationMethodSelectorProps) {
    const { error: queryError, data } = useCalculationMethodsQuery();

    useEffect(() => {
        if (queryError) message.error(`Calculation methods query error: ${queryError}`, 5);
    }, [queryError]);

    return (
        <Select value={value} onChange={onChange}>
            {data?.calculationMethods.map(({ value, label }) => (
                <Select.Option key={`option-${value}`} value={Number.parseInt(value)}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    );
}
