import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Spin, message, PageHeader } from "antd";
import { useBuildingByIdQuery, useReportByIdQuery } from "../../graphql/operations";
import { ReportForm } from "../ReportForm";

export const ReportEdit: React.FC = () => {
    const { buildingId, reportId } = useParams<{ buildingId: string; reportId: string }>();

    if (buildingId === undefined) throw new Error("No building ID provided");
    if (reportId === undefined) throw new Error("No report ID provided");

    const {
        loading: buildingLoading,
        error: buildingError,
        data: buildingData,
    } = useBuildingByIdQuery({
        variables: { id: buildingId },
    });
    const {
        loading: reportLoading,
        error: reportError,
        data: reportData,
    } = useReportByIdQuery({
        variables: { id: reportId },
    });

    useEffect(() => {
        if (buildingError) message.error(`Building by ID query error: ${buildingError}`, 5);
        if (reportError) message.error(`Report query error: ${reportError}`, 5);
    }, [buildingError, reportError]);

    if (buildingLoading || !buildingData || !buildingData.building) return <Spin />;
    if (reportLoading || !reportData || !reportData.report) return <Spin />;

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <Link to="/reports">Reports</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/building/${buildingData.building.id}`}>{buildingData.building.code}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{reportData.report.description}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title={`Report ${reportData.report.description}`} />
                <ReportForm building={buildingData.building} initialValues={reportData.report} />
            </div>
        </>
    );
};
