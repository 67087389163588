import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { useBuildingExpenseCategoriesQuery } from "../../graphql/operations";
import { NewTable } from "../NewTable/NewTable";
import { ExpenseCategoriesRow } from "./ExpenseCategoriesRow";
import { ExpenseCategory } from "./ExpenseCategoriesRow";

type ExpenseCategoriesProps = {
    buildingId: string;
};

export function ExpenseCategories({ buildingId }: ExpenseCategoriesProps) {
    const { data, loading, error } = useBuildingExpenseCategoriesQuery({ variables: { buildingId } });

    useEffect(() => {
        if (error) message.error(`Query error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.building?.expenseCategories) return <Spin />;

    return (
        <NewTable<ExpenseCategory, { buildingId: string }>
            header={["Name", "Method", "Fixed", "Floor increment"]}
            dataSource={data.building.expenseCategories}
            RowComponent={ExpenseCategoriesRow}
            addButtonText="Add expense category"
            saveButtonText="Save expense category changes"
            extraRowProps={{ buildingId }}
        />
    );
}
