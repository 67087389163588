import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { useReportExpensesQuery } from "../../graphql/operations";
import { NewTable } from "../NewTable/NewTable";
import { Expense, ExpenseRow, ExpensesRowExtraProps } from "./ExpensesRow";

type ExpensesProps = {
    buildingId: string;
    reportId: string;
};

export function Expenses({ buildingId, reportId }: ExpensesProps) {
    const { data, loading, error } = useReportExpensesQuery({ variables: { id: reportId } });

    useEffect(() => {
        if (error) message.error(`Query error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.report?.expenses) return <Spin />;

    return (
        <NewTable<Expense, ExpensesRowExtraProps>
            header={["Category", "Description", "Amount", ""]}
            dataSource={data.report.expenses}
            RowComponent={ExpenseRow}
            addButtonText="Add expense"
            saveButtonText="Save expense changes"
            extraRowProps={{ buildingId, reportId }}
            // alwaysEmptyRow
        />
    );
}
