import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, message, PageHeader, Spin } from "antd";
import { useBuildingByIdQuery } from "../graphql/operations";
import { ReportForm } from "./ReportForm";

export const ReportAdd: React.FC = () => {
    const { buildingId } = useParams<{ buildingId: string }>();

    const navigate = useNavigate();

    if (buildingId === undefined) throw new Error("No building ID provided");

    const { loading, error, data } = useBuildingByIdQuery({
        variables: { id: buildingId },
    });

    useEffect(() => {
        if (error) message.error(`Mutation error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.building) return <Spin />;

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <Link to="/reports">Reports</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/report/${data.building.id}`}>{data.building.code}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New report</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title={`Create report for building ${data.building.code}`} />
                <ReportForm
                    building={data.building}
                    onFinish={(report) => navigate(`/building/${buildingId}/report/${report.id}`)}
                />
            </div>
        </>
    );
};
