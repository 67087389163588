import React, { useEffect } from "react";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Tabs, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { BuildingByIdQuery, SaveReportInput } from "../../graphql/types";
import { useSaveReportMutation } from "graphql/operations";
import { toCamelCase } from "../util";
import { Expenses } from "./Expenses";

export type ReportFormData = Omit<SaveReportInput, "active" | "clientMutationId" | "building" | "data"> & {
    alternativeDescription?: moment.Moment[];
};

type ReportFormProps = {
    building: NonNullable<BuildingByIdQuery["building"]>;
    initialValues?: ReportFormData;
    onFinish?: (report: ReportFormData) => Promise<void> | void;
};

export const ReportForm: React.FC<ReportFormProps> = ({ building, initialValues, onFinish }) => {
    const [saveMutation, { called, loading, error, data }] = useSaveReportMutation({ refetchQueries: ["Reports"] });
    const [form] = useForm<ReportFormData>();

    useEffect(() => {
        if (error) message.error(`Mutation error: ${error}`, 5);
    }, [error]);

    useEffect(() => {
        if (data?.saveReport?.errors) {
            form.setFields(
                data?.saveReport?.errors.map((err) => ({
                    name: toCamelCase(err?.field!),
                    errors: err?.messages!,
                }))
            );
        }

        if (onFinish && called && !loading && data?.saveReport?.errors?.length === 0 && data?.saveReport?.report) {
            onFinish(data.saveReport.report);
        }
    }, [form, onFinish, called, loading, data]);

    useEffect(() => {
        if (initialValues)
            form.setFieldsValue({
                ...initialValues,
                date: moment(initialValues.date),
            });
    }, [form, initialValues]);

    return (
        <>
            <Form
                form={form}
                name="report-form"
                onFinish={(values: ReportFormData) => {
                    let description = values.description;
                    if (!description) {
                        if (!values.alternativeDescription)
                            throw new Error("Neither description nor alternative description is provided");

                        description = `${values.alternativeDescription[0].format(
                            "DD/MM/YYYY"
                        )}-${values.alternativeDescription[1].format("DD/MM/YYYY")}`;
                    }

                    saveMutation({
                        variables: {
                            input: {
                                id: form.getFieldValue("id") ? form.getFieldValue("id") : undefined,
                                building: building.id,
                                ...values,
                                date: values.date.toISOString().split("T")[0] as unknown as Date,
                            },
                        },
                    });
                }}
            >
                <Row>
                    <Col span={4}>
                        Address: {building.street} {building.streetNum}
                    </Col>
                    <Col span={5}>
                        Manager: {building.managerFirstName} {building.managerLastName} - {building.phoneMobile}
                    </Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Form.Item label="Creation date" name="date">
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" />
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Description" name="description">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="or" name="alternativeDescription">
                            <DatePicker.RangePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={1} offset={22}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {initialValues !== undefined && (
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Expenses" key="1">
                        <Expenses buildingId={building.id} reportId={initialValues.id!} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Time / Calories" key="2">
                        Content of Tab Pane 2
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Notes" key="3">
                        Content of Tab Pane 3
                    </Tabs.TabPane>
                </Tabs>
            )}
        </>
    );
};
