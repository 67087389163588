import React, { ComponentType, useEffect, useState } from "react";
import { Button, FormInstance } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { NewTableSaveButton } from "./NewTableSaveButton";

import "./NewTable.scss";

type NewTableConntextType = {
    forms: { [key: number]: FormInstance };
    changed: boolean;
    setChanged: (value: boolean) => void;
};

export const NewTableContext = React.createContext<NewTableConntextType>({
    forms: {},
    changed: false,
    setChanged: () => {
        /* Nothing to do here */
    },
});

type RowProps<T, S> = {
    key: number;
    index: number;
    record: T;
    extraRowProps: S;
};

type NewTableProps<T, S> = {
    header: string[];
    dataSource?: T[];
    RowComponent: ComponentType<RowProps<T, S>>;
    alwaysEmptyRow?: boolean;
    withAddButton?: boolean;
    addButtonText?: string;
    saveButtonText?: string;
    extraRowProps?: S;
};

export function NewTable<T, S>({
    header,
    dataSource,
    RowComponent,
    alwaysEmptyRow,
    withAddButton,
    addButtonText,
    saveButtonText,
    extraRowProps = {} as S,
}: NewTableProps<T, S>) {
    const [changed, setChanged] = useState<boolean>(false);
    const [data, setData] = useState<T[]>(dataSource || []);

    useEffect(() => {
        setData([...(dataSource || []), ...(alwaysEmptyRow ? [{} as T] : [])]);
    }, [dataSource]);

    return (
        <NewTableContext.Provider value={{ forms: [], changed, setChanged }}>
            <div className="ant-table-wrapper">
                <div className="ant-table ant-table-bordered">
                    <div className="ant-table-container">
                        <div className="ant-table-content">
                            <table style={{ tableLayout: "auto" }}>
                                <thead className="ant-table-thead thin-ant-table-thead">
                                    <tr>
                                        {header.map((title) => (
                                            <th key={title} className="ant-table-cell">
                                                {title}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody thin-ant-table-tbody">
                                    {data?.map((record, index) => (
                                        <RowComponent
                                            key={index}
                                            index={index}
                                            record={record}
                                            extraRowProps={extraRowProps}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {withAddButton || addButtonText ? (
                <Button
                    type="primary"
                    style={{ marginRight: 10, marginTop: 10 }}
                    onClick={() => {
                        setData([...data, {} as T]);
                    }}
                >
                    <AppstoreAddOutlined /> {addButtonText || "Add row"}
                </Button>
            ) : null}{" "}
            <NewTableSaveButton addButtonText={addButtonText} saveButtonText={saveButtonText} />
        </NewTableContext.Provider>
    );
}
