import React, { useEffect } from "react";
import { Breadcrumb, Spin, message, PageHeader } from "antd";
import { Link, useParams } from "react-router-dom";
import { useBuildingByIdQuery } from "../../graphql/operations";
import { BuildingForm } from "../BuildingForm";
import { ExpenseCategories } from "./ExpenseCategories";
import { Apartments } from "./Apartments";
import { ApartmentDiscounts } from "./ApartmentDiscounts";
import { ApartmentShares } from "./ApartmentShares";

type BuildingEditParams = {
    id: string;
};

export const BuildingEdit: React.FC = () => {
    const { id } = useParams<BuildingEditParams>();

    if (id === undefined) throw new Error("No building ID provided");

    const { loading, error, data } = useBuildingByIdQuery({ variables: { id } });

    useEffect(() => {
        if (error) message.error(`BuildingByIdQuery error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.building) return <Spin />;

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <Link to="/">Buildings</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{data.building.code}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title={`Building ${data.building.code}`} />
                <h2>Building details</h2>
                <BuildingForm initialValues={data.building} />
                <h2>Expense categories</h2>
                <ExpenseCategories buildingId={data.building.id} />
                <h2>Apartments</h2>
                <Apartments buildingId={data.building.id} />
                <h2>Discounts</h2>
                <ApartmentDiscounts buildingId={data.building.id} />
                <h2>Other shares</h2>
                <ApartmentShares buildingId={data.building.id} />
            </div>
        </>
    );
};
