import React, { useContext } from "react";
import { Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { NewTableContext } from "./NewTable";

type NewTableSaveButtonProps = {
    addButtonText?: string;
    saveButtonText?: string;
};

export function NewTableSaveButton({ addButtonText, saveButtonText }: NewTableSaveButtonProps) {
    const context = useContext(NewTableContext);

    return (
        <Button
            type="primary"
            style={{ marginTop: 10 }}
            disabled={!context.changed}
            onClick={() => {
                Object.values(context.forms).forEach((form) => {
                    if (form.isFieldsTouched()) {
                        form.submit();
                    }
                });
            }}
        >
            <SaveOutlined /> {saveButtonText || "Save"}
        </Button>
    );
}
