import React, { useEffect } from "react";
import { Select, message } from "antd";
import { useBuildingExpenseCategoriesQuery } from "graphql/operations";

type ExpenseCategorySelectorProps = {
    buildingId: string;
    value?: string;
    onChange?: (value: string | undefined) => void;
};

export function ExpenseCategorySelector({ buildingId, value, onChange }: ExpenseCategorySelectorProps) {
    const { error: queryError, data } = useBuildingExpenseCategoriesQuery({ variables: { buildingId } });

    useEffect(() => {
        if (queryError) message.error(`Calculation methods query error: ${queryError}`, 5);
    }, [queryError]);

    return (
        <Select value={value} onChange={onChange}>
            {data?.building?.expenseCategories.map(({ id, name }) => (
                <Select.Option key={`expense-category-option-${id}`} value={Number.parseInt(id)}>
                    {name}
                </Select.Option>
            ))}
        </Select>
    );
}
