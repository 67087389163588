import React, { useContext, useEffect, useState } from "react";
import { Form, Input, InputNumber, message, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { ReportExpensesDocument, useDeleteExpenseMutation, useSaveExpenseMutation } from "graphql/operations";
import { NewTableContext } from "../NewTable/NewTable";
import { toCamelCase } from "../util";
import { ReportExpensesQuery } from "graphql/types";
import { ExpenseCategorySelector } from "components/util/ExpenseCategorySelector";

export type Expense = Omit<NonNullable<ReportExpensesQuery["report"]>["expenses"][number], "__typename">;

export type ExpensesRowExtraProps = { buildingId: string; reportId: string };
type ExpensesRowProps = { index: number; record: Expense; extraRowProps: ExpensesRowExtraProps };

export function ExpenseRow({ index, record, extraRowProps: { buildingId, reportId } }: ExpensesRowProps) {
    const [saveMutation, { called, loading, error: saveMutationError, data, reset: resetMutation }] =
        useSaveExpenseMutation({
            awaitRefetchQueries: true,
            refetchQueries: [{ query: ReportExpensesDocument, variables: { id: reportId } }],
        });
    const [deleteMutation, { error: deleteMutationError }] = useDeleteExpenseMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: ReportExpensesDocument, variables: { id: reportId } }],
    });

    const context = useContext(NewTableContext);
    const [form] = useForm();
    context.forms[index] = form;

    useEffect(() => {
        if (saveMutationError) message.error(`Mutation error: ${saveMutationError}`, 5);
        if (deleteMutationError) message.error(`Mutation error: ${deleteMutationError}`, 5);
    }, [saveMutationError, deleteMutationError]);

    const [otherErrors, setOtherErrors] = useState<string>("");
    useEffect(() => {
        if (data?.saveExpense?.errors) {
            const errorFields: string[] = [];
            form?.setFields(
                data?.saveExpense.errors.map((err) => {
                    const fieldName = toCamelCase(err?.field!);
                    errorFields.push(fieldName);
                    return {
                        name: fieldName,
                        errors: err?.messages!,
                    };
                })
            );
            const resolvedErrors = form
                .getFieldsError()
                .filter((err) => err.errors.length)
                .filter((err) => !errorFields.includes(err.name[0].toString()));
            form.setFields(
                resolvedErrors.map((err) => ({
                    name: err.name[0],
                    errors: [],
                }))
            );

            setOtherErrors(
                data?.saveExpense.errors.reduce((accu, err) => {
                    if (err && !["name", "method", "fixed", "floorIncrement"].includes(err?.field)) {
                        accu += `${err.field}: ${err.messages.join(" ")}\n`;
                    }

                    return accu;
                }, "")
            );
        }

        if (called && !loading && data?.saveExpense?.errors?.length === 0 && data?.saveExpense?.expense) {
            setOtherErrors("");
            form.setFieldValue("pendingChanges", false);
            context.setChanged(Object.values(context.forms).some((form) => form.getFieldValue("pendingChanges")));
            form.resetFields();
        }

        resetMutation();
    }, [form, called, loading, data]);

    return (
        <Form
            form={form}
            component={false}
            initialValues={record}
            onFinish={(values: Expense) => {
                saveMutation({
                    variables: {
                        input: {
                            ...values,
                            id: values.id ? values.id : undefined,
                            report: reportId,
                        },
                    },
                });
            }}
        >
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">
                    <Form.Item name="category" className="thin-ant-form-item">
                        <ExpenseCategorySelector
                            buildingId={buildingId}
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Form.Item name="description" className="thin-ant-form-item">
                        <Input
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Form.Item name="amount" className="thin-ant-form-item">
                        <InputNumber
                            className="wide-ant-input-number"
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Popconfirm
                        style={{ paddingLeft: "10px" }}
                        title="Sure to delete?"
                        onConfirm={() => {
                            deleteMutation({ variables: { args: { id: record.id } } });
                        }}
                    >
                        <a>
                            <DeleteOutlined style={{ paddingLeft: "7px", paddingRight: "7px" }} />
                        </a>
                    </Popconfirm>
                    <div className="ant-form-item-explain-error">{otherErrors}</div>
                </td>
            </tr>
        </Form>
    );
}
