import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, PageHeader } from "antd";
import { BuildingForm } from "./BuildingForm";

export const BuildingAdd: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>
                    <Link to="/">Buildings</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title="New building" />
                <BuildingForm onFinish={(building) => navigate(`/building/${building.id}`)} />
            </div>
        </>
    );
};
