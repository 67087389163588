import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { useApartmentDiscountsQuery, useBuildingExpenseCategoriesQuery } from "../../graphql/operations";
import { NewTable } from "../NewTable/NewTable";
import { ApartmentDiscount, ApartmentDiscountRow, ApartmentDiscountRowPropsExtra } from "./ApartmentDiscountRow";

type ApartmentDiscountsProps = {
    buildingId: string;
};

export function ApartmentDiscounts({ buildingId }: ApartmentDiscountsProps) {
    const {
        data: expenseCategoryData,
        error: expenseCategoryError,
        loading: expenseCategoryLoading,
    } = useBuildingExpenseCategoriesQuery({ variables: { buildingId } });
    const {
        data: discountData,
        error: discountError,
        loading: discountLoading,
    } = useApartmentDiscountsQuery({
        variables: { id: buildingId },
    });

    useEffect(() => {
        if (expenseCategoryError) message.error(`Query error: ${expenseCategoryError}`, 5);
        if (discountError) message.error(`Query error: ${discountError}`, 5);
    }, [expenseCategoryError, discountError]);

    const [categories, setCategories] = useState<{ id: string; name: string }[]>([]);
    useEffect(() => {
        setCategories(expenseCategoryData?.building?.expenseCategories.map(({ id, name }) => ({ id, name })) || []);
    }, [expenseCategoryData]);

    if (expenseCategoryLoading || !expenseCategoryData || !expenseCategoryData.building?.expenseCategories)
        return <Spin />;

    if (discountLoading || !discountData || !discountData.building?.apartments) return <Spin />;

    return (
        <NewTable<ApartmentDiscount, ApartmentDiscountRowPropsExtra>
            header={["Apartment"].concat(categories.map(({ name }) => name))}
            dataSource={discountData?.building?.apartments}
            RowComponent={ApartmentDiscountRow}
            addButtonText="Add discount"
            saveButtonText="Save discount changes"
            extraRowProps={{ buildingId, categories: categories }}
        />
    );
}
