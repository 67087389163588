import React from "react";
import { Breadcrumb } from "antd";
import { DefaultExpenseCategories } from "./DefaultExpenseCategories";

interface SettingsProps {
    username: string;
}

export const Settings: React.FC<SettingsProps> = ({ username }) => {
    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <DefaultExpenseCategories />
            </div>
        </>
    );
};
