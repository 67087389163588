import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { Button, Form, Input, Popconfirm, Space, Table, message, PageHeader, Breadcrumb } from "antd";
import { DeleteOutlined, EditOutlined, FileOutlined, QuestionCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { BuildingsDocument, useBuildingsQuery, useDeleteBuildingMutation } from "../graphql/operations";
import { BuildingsQuery } from "../graphql/types";

type Building = BuildingsQuery["buildings"]["buildings"][number];

export const Buildings: React.FC = () => {
    const [searchString, setSearchString] = useState("");

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const { loading, error, data } = useBuildingsQuery({ variables: { searchString, page, pageSize } });
    const [deleteBuilding, { error: mutationError }] = useDeleteBuildingMutation({
        awaitRefetchQueries: true,
        ignoreResults: true,
        refetchQueries: [{ query: BuildingsDocument }],
    });

    useEffect(() => {
        if (error) message.error(`Buildings query error: ${error}`, 5);
        if (mutationError) message.error(`Mutation error: ${mutationError}`, 5);
    }, [error, mutationError]);

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Buildings</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title="Buildings" />
                <Form
                    name="building_search"
                    layout="inline"
                    style={{ marginBottom: "20px" }}
                    onValuesChange={debounce(
                        (values: { searchString: string }) => setSearchString(values.searchString),
                        500
                    )}
                >
                    <Form.Item name="searchString" label="Search" style={{ width: "30%" }}>
                        <Input placeholder="Search for Code, Street, City, Postcode, Region or Manager name" />
                    </Form.Item>
                </Form>
                <Table
                    dataSource={data?.buildings.buildings}
                    bordered={true}
                    loading={loading}
                    rowKey="id"
                    pagination={{
                        pageSize,
                        defaultCurrent: 1,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, pageSize) => {
                            setPage(current);
                            setPageSize(pageSize);
                        },
                        total: data?.buildings.totalCount,
                    }}
                >
                    <Table.Column
                        title="Code"
                        dataIndex="code"
                        key="code"
                        sorter={(a: Building, b: Building) => a.code.localeCompare(b.code)}
                    />
                    <Table.ColumnGroup title="Address">
                        <Table.Column
                            title="Street"
                            dataIndex="street"
                            key="street"
                            sorter={(a: Building, b: Building) => a.street.localeCompare(b.street)}
                        />
                        <Table.Column
                            title="Number"
                            dataIndex="streetNum"
                            key="streetNum"
                            sorter={(a: Building, b: Building) => a.streetNum.localeCompare(b.streetNum)}
                        />
                        <Table.Column
                            title="City"
                            dataIndex="city"
                            key="city"
                            sorter={(a: Building, b: Building) => a.city.localeCompare(b.city)}
                        />
                        <Table.Column
                            title="PostCode"
                            dataIndex="postalCode"
                            key="postalCode"
                            sorter={(a: Building, b: Building) => a.postalCode.localeCompare(b.postalCode)}
                        />
                        <Table.Column
                            title="Region"
                            dataIndex="region"
                            key="region"
                            sorter={(a: Building, b: Building) => a.region.localeCompare(b.region)}
                        />
                    </Table.ColumnGroup>
                    <Table.ColumnGroup title="Manager">
                        <Table.Column
                            title="First name"
                            dataIndex="managerFirstName"
                            key="managerFirstName"
                            sorter={(a: Building, b: Building) => a.managerFirstName.localeCompare(b.managerFirstName)}
                        />
                        <Table.Column
                            title="Last name"
                            dataIndex="managerLastName"
                            key="managerLastName"
                            sorter={(a: Building, b: Building) => a.managerLastName.localeCompare(b.managerLastName)}
                        />
                    </Table.ColumnGroup>
                    <Table.Column
                        title="Actions"
                        key="building-list-actions"
                        render={(text, record: { id: string }) => (
                            <Space size="middle">
                                <Link to={`/building/${record.id}/report/new`}>
                                    <Button>
                                        <FileOutlined /> New report
                                    </Button>
                                </Link>
                                <Link to={`/building/${record.id}`}>
                                    <Button>
                                        <EditOutlined /> Edit
                                    </Button>
                                </Link>
                                <Popconfirm
                                    title="Confirm delete"
                                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                                    onConfirm={() => deleteBuilding({ variables: { args: { id: record.id } } })}
                                >
                                    <Button>
                                        <DeleteOutlined /> Delete
                                    </Button>
                                </Popconfirm>
                            </Space>
                        )}
                    />
                </Table>
                <Link to="building/add">
                    <Button type="primary">
                        <PlusOutlined /> Add building
                    </Button>
                </Link>
            </div>
        </>
    );
};
