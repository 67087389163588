import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { Form, Input, Table, message, Breadcrumb, PageHeader } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { useReportsQuery } from "../graphql/operations";
import { ReportsQuery } from "../graphql/types";

type Report = ReportsQuery["reports"]["reports"][number];

export const Reports: React.FC = () => {
    const [searchString, setSearchString] = useState("");

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const { loading, error, data } = useReportsQuery({ variables: { searchString, page, pageSize } });

    useEffect(() => {
        if (error) message.error(`Reports query error: ${error}`, 5);
    }, [error]);

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <PageHeader title="Reports" />
                <Form
                    name="building_search"
                    layout="inline"
                    style={{ marginBottom: "20px" }}
                    onValuesChange={debounce(
                        (values: { searchString: string }) => setSearchString(values.searchString),
                        500
                    )}
                >
                    <Form.Item name="searchString" label="Search" style={{ width: "30%" }}>
                        <Input placeholder="Search for Code, Street, City, Postcode, Region or Manager name" />
                    </Form.Item>
                </Form>
                <Table
                    dataSource={data?.reports.reports}
                    bordered={true}
                    loading={loading}
                    rowKey="id"
                    pagination={{
                        pageSize,
                        defaultCurrent: 1,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, pageSize) => {
                            setPage(current);
                            setPageSize(pageSize);
                        },
                        total: data?.reports.totalCount,
                    }}
                >
                    <Table.Column
                        title="Building"
                        key="code"
                        sorter={(a: Report, b: Report) => a.building.code.localeCompare(b.building.code)}
                        render={(text, record: Report) => record.building.code}
                    />
                    <Table.Column
                        title="Date"
                        dataIndex="date"
                        key="date"
                        sorter={(a: Report, b: Report) => a.date.toString().localeCompare(b.date.toString())}
                    />
                    <Table.Column title="Description" dataIndex="description" key="description" />
                    <Table.Column
                        title="Actions"
                        key="reports-list-actions"
                        render={(text, record: Report) => (
                            <Link to={`/building/${record.building.id}/report/${record.id}`}>
                                <FileOutlined /> Edit
                            </Link>
                        )}
                    />
                </Table>
            </div>
        </>
    );
};
