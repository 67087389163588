import React, { useContext, useEffect, useState } from "react";
import { Form, Input, InputNumber, message, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import {
    DefaultExpenseCategoriesDocument,
    useDeleteDefaultCategoryMutation,
    useSaveDefaultCategoryMutation,
} from "graphql/operations";
import { NewTableContext } from "../NewTable/NewTable";
import { CalculationMethodsSelector, toCamelCase } from "../util";
import { DefaultExpenseCategoriesQuery } from "graphql/types";

export type DefaultExpenseCategory = Omit<
    DefaultExpenseCategoriesQuery["defaultExpenseCategories"][number],
    "__typename"
>;

type DefaultExpenseCategoriesRowProps = { index: number; record: DefaultExpenseCategory };

export function DefaultExpenseCategoriesRow({ index, record }: DefaultExpenseCategoriesRowProps) {
    const [saveMutation, { called, loading, error: saveMutationError, data, reset: resetMutation }] =
        useSaveDefaultCategoryMutation({
            awaitRefetchQueries: true,
            refetchQueries: ["DefaultExpenseCategories"],
        });
    const [deleteMutation, { error: deleteMutationError }] = useDeleteDefaultCategoryMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: DefaultExpenseCategoriesDocument }],
    });

    const context = useContext(NewTableContext);
    const [form] = useForm();
    context.forms[index] = form;

    useEffect(() => {
        if (saveMutationError) message.error(`Mutation error: ${saveMutationError}`, 5);
        if (deleteMutationError) message.error(`Mutation error: ${deleteMutationError}`, 5);
    }, [saveMutationError, deleteMutationError]);

    const [otherErrors, setOtherErrors] = useState<string>("");
    useEffect(() => {
        if (data?.saveDefaultCategory?.errors) {
            const errorFields: string[] = [];
            form?.setFields(
                data?.saveDefaultCategory.errors.map((err) => {
                    const fieldName = toCamelCase(err?.field!);
                    errorFields.push(fieldName);
                    return {
                        name: fieldName,
                        errors: err?.messages!,
                    };
                })
            );
            const resolvedErrors = form
                .getFieldsError()
                .filter((err) => err.errors.length)
                .filter((err) => !errorFields.includes(err.name[0].toString()));
            form.setFields(
                resolvedErrors.map((err) => ({
                    name: err.name[0],
                    errors: [],
                }))
            );

            setOtherErrors(
                data?.saveDefaultCategory.errors.reduce((accu, err) => {
                    if (err && !["name", "method", "fixed", "floorIncrement"].includes(err?.field)) {
                        accu += `${err.field}: ${err.messages.join(" ")}\n`;
                    }

                    return accu;
                }, "")
            );
        }

        if (
            called &&
            !loading &&
            data?.saveDefaultCategory?.errors?.length === 0 &&
            data?.saveDefaultCategory?.defaultCategory
        ) {
            setOtherErrors("");
            form.setFieldValue("pendingChanges", false);
            context.setChanged(Object.values(context.forms).some((form) => form.getFieldValue("pendingChanges")));
        }

        resetMutation();
    }, [form, called, loading, data]);

    return (
        <Form
            form={form}
            component={false}
            initialValues={record}
            onFinish={(values: DefaultExpenseCategory) => {
                if (form.getFieldValue("id")) {
                    saveMutation({
                        variables: {
                            input: { ...values, method: values.method.toString(), id: form.getFieldValue("id") },
                        },
                    });
                } else {
                    saveMutation({ variables: { input: { ...values, method: values.method.toString() } } });
                }
            }}
        >
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">
                    <Form.Item name="name" className="thin-ant-form-item">
                        <Input
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Form.Item name="method" className="thin-ant-form-item">
                        <CalculationMethodsSelector
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Form.Item name="fixed" className="thin-ant-form-item">
                        <InputNumber
                            className="wide-ant-input-number"
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Form.Item name="floorIncrement" className="thin-ant-form-item">
                        <InputNumber
                            className="wide-ant-input-number"
                            onChange={() => {
                                form.setFieldValue("pendingChanges", true);
                                context.setChanged(true);
                            }}
                        />
                    </Form.Item>
                </td>
                <td className="ant-table-cell">
                    <Popconfirm
                        style={{ paddingLeft: "10px" }}
                        title="Sure to delete?"
                        onConfirm={() => {
                            deleteMutation({ variables: { args: { id: record.id } } });
                        }}
                    >
                        <a>
                            <DeleteOutlined style={{ paddingLeft: "7px" }} />
                        </a>
                    </Popconfirm>
                    <div className="ant-form-item-explain-error">{otherErrors}</div>
                </td>
            </tr>
        </Form>
    );
}
