import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { useDefaultExpenseCategoriesQuery } from "../../graphql/operations";
import { NewTable } from "../NewTable/NewTable";
import { DefaultExpenseCategoriesRow, DefaultExpenseCategory } from "./DefaultExpenseCategoriesRow";

export const DefaultExpenseCategories: React.FC = () => {
    const { data, loading, error } = useDefaultExpenseCategoriesQuery();

    useEffect(() => {
        if (error) message.error(`Query error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.defaultExpenseCategories) return <Spin />;

    return (
        <>
            <h2>Default expense categories</h2>
            <NewTable<DefaultExpenseCategory, null>
                header={["Name", "Method", "Fixed", "Floor increment"]}
                dataSource={data.defaultExpenseCategories}
                RowComponent={DefaultExpenseCategoriesRow}
                addButtonText="Add default category"
                saveButtonText="Save default category changes"
            />
        </>
    );
};
