import React, { useContext, useEffect, useState } from "react";
import { Form, InputNumber, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ApartmentDiscountsDocument, useSaveApartmentDiscountMutation } from "graphql/operations";
import { NewTableContext } from "../NewTable/NewTable";
import { toCamelCase } from "../util";
import { ApartmentDiscountsQuery } from "graphql/types";

export type ApartmentDiscount = Omit<
    NonNullable<ApartmentDiscountsQuery["building"]>["apartments"][number],
    "__typename"
>;

export type ApartmentDiscountRowPropsExtra = { buildingId: string; categories: { id: string; name: string }[] };

type ApartmentDiscountRowProps = {
    index: number;
    record: ApartmentDiscount;
    extraRowProps: ApartmentDiscountRowPropsExtra;
};

export function ApartmentDiscountRow({
    index,
    record,
    extraRowProps: { buildingId, categories },
}: ApartmentDiscountRowProps) {
    const [saveMutation, { called, loading, error: saveMutationError, data, reset: resetMutation }] =
        useSaveApartmentDiscountMutation({
            awaitRefetchQueries: true,
            refetchQueries: [{ query: ApartmentDiscountsDocument, variables: { id: buildingId } }],
        });

    const context = useContext(NewTableContext);
    const [form] = useForm();
    context.forms[index] = form;

    useEffect(() => {
        if (saveMutationError) message.error(`Mutation error: ${saveMutationError}`, 5);
    }, [saveMutationError]);

    const [otherErrors, setOtherErrors] = useState<string>("");
    useEffect(() => {
        if (data?.saveApartmentDiscount?.errors) {
            const errorFields: string[] = [];
            form?.setFields(
                data?.saveApartmentDiscount.errors.map((err) => {
                    const fieldName = toCamelCase(err?.field!);
                    errorFields.push(fieldName);
                    return {
                        name: fieldName,
                        errors: err?.messages!,
                    };
                })
            );
            const resolvedErrors = form
                .getFieldsError()
                .filter((err) => err.errors.length)
                .filter((err) => !errorFields.includes(err.name[0].toString()));
            form.setFields(
                resolvedErrors.map((err) => ({
                    name: err.name[0],
                    errors: [],
                }))
            );

            setOtherErrors(
                data?.saveApartmentDiscount.errors.reduce((accu, err) => {
                    if (err && !["name", "method", "fixed", "floorIncrement"].includes(err?.field)) {
                        accu += `${err.field}: ${err.messages.join(" ")}\n`;
                    }

                    return accu;
                }, "")
            );
        }

        if (
            called &&
            !loading &&
            data?.saveApartmentDiscount?.errors?.length === 0 &&
            data?.saveApartmentDiscount?.apartmentDiscount
        ) {
            setOtherErrors("");
            form.setFieldValue("pendingChanges", false);
            context.setChanged(Object.values(context.forms).some((form) => form.getFieldValue("pendingChanges")));
        }

        resetMutation();
    }, [form, called, loading, data]);

    useEffect(() => {
        const initialValues: { [key: string]: number } = {};
        categories.forEach((category) => {
            const categoryDiscount = record.discounts.find((discount) => discount.category.name === category.name);
            initialValues[category.id] = categoryDiscount ? categoryDiscount.discount : 0;
        });
        form.setFieldsValue(initialValues);
    }, [record]);

    return (
        <Form
            form={form}
            component={false}
            onFinish={(values) => {
                categories.forEach((category) => {
                    const existingDiscountId = record.discounts.find(
                        (discount) => discount.category.id === category.id
                    )?.id;

                    saveMutation({
                        variables: {
                            input: {
                                id: existingDiscountId ? existingDiscountId : undefined,
                                apartment: record.id,
                                category: category.id,
                                discount: values[category.id],
                            },
                        },
                    });
                });
            }}
        >
            <tr className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">{record.code}</td>
                {categories.map((category) => (
                    <td key={category.id} className="ant-table-cell">
                        <Form.Item name={category.id} className="thin-ant-form-item">
                            <InputNumber
                                className="wide-ant-input-number"
                                onChange={() => {
                                    form.setFieldValue("pendingChanges", true);
                                    context.setChanged(true);
                                }}
                            />
                        </Form.Item>
                    </td>
                ))}
                <td className="ant-table-cell">
                    <div className="ant-form-item-explain-error">{otherErrors}</div>
                </td>
            </tr>
        </Form>
    );
}
