/* eslint-disable */
import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export const ErrorsFragmentFragmentDoc = gql`
    fragment ErrorsFragment on ErrorType {
        field
        messages
    }
`;
export const BuildingBasicFragmentFragmentDoc = gql`
    fragment BuildingBasicFragment on Building {
        id
        code
        floorCount
        street
        streetNum
        city
        postalCode
        region
        managerFirstName
        managerLastName
    }
`;
export const BuildingExtendedFragmentFragmentDoc = gql`
    fragment BuildingExtendedFragment on Building {
        ...BuildingBasicFragment
        isManual
        price
        isPricePerApartment
        vat
        phoneHome
        phoneMobile
        phoneWork
        email
        notesVisible
        notesInvisible
    }
    ${BuildingBasicFragmentFragmentDoc}
`;
export const ApartmentFragmentFragmentDoc = gql`
    fragment ApartmentFragment on Apartment {
        id
        code
        leseeFname
        leseeLname
        ownerFname
        ownerLname
        isManager
        isClosed
        area
        mills
        floor
        ei
        fi
        notes
    }
`;
export const ExpenseCategoryFragmentFragmentDoc = gql`
    fragment ExpenseCategoryFragment on ExpenseCategory {
        id
        name
        method
        fixed
        floorIncrement
    }
`;
export const DefaultCategoryFragmentFragmentDoc = gql`
    fragment DefaultCategoryFragment on DefaultCategory {
        id
        name
        method
        fixed
        floorIncrement
    }
`;
export const ReportFragmentFragmentDoc = gql`
    fragment ReportFragment on Report {
        id
        date
        description
    }
`;
export const ExpenseFragmentFragmentDoc = gql`
    fragment ExpenseFragment on Expense {
        amount
        category
        description
        id
        report {
            id
        }
    }
`;
export const ApartmentDiscountFragmentFragmentDoc = gql`
    fragment ApartmentDiscountFragment on ApartmentDiscount {
        id
        apartment {
            id
        }
        category {
            id
        }
        discount
    }
`;
export const ApartmentShareFragmentFragmentDoc = gql`
    fragment ApartmentShareFragment on ApartmentShare {
        id
        category {
            id
        }
        value
    }
`;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
            token
        }
    }
`;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const SaveBuildingDocument = gql`
    mutation SaveBuilding($input: SaveBuildingInput!) {
        saveBuilding(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            building {
                ...BuildingExtendedFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${BuildingExtendedFragmentFragmentDoc}
`;
export type SaveBuildingMutationFn = Apollo.MutationFunction<
    Types.SaveBuildingMutation,
    Types.SaveBuildingMutationVariables
>;

/**
 * __useSaveBuildingMutation__
 *
 * To run a mutation, you first call `useSaveBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBuildingMutation, { data, loading, error }] = useSaveBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBuildingMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.SaveBuildingMutation, Types.SaveBuildingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveBuildingMutation, Types.SaveBuildingMutationVariables>(
        SaveBuildingDocument,
        options
    );
}
export type SaveBuildingMutationHookResult = ReturnType<typeof useSaveBuildingMutation>;
export type SaveBuildingMutationResult = Apollo.MutationResult<Types.SaveBuildingMutation>;
export type SaveBuildingMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveBuildingMutation,
    Types.SaveBuildingMutationVariables
>;
export const DeleteBuildingDocument = gql`
    mutation DeleteBuilding($args: DeleteBuildingInput!) {
        deleteBuilding(args: $args) {
            success
        }
    }
`;
export type DeleteBuildingMutationFn = Apollo.MutationFunction<
    Types.DeleteBuildingMutation,
    Types.DeleteBuildingMutationVariables
>;

/**
 * __useDeleteBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingMutation, { data, loading, error }] = useDeleteBuildingMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteBuildingMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.DeleteBuildingMutation, Types.DeleteBuildingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteBuildingMutation, Types.DeleteBuildingMutationVariables>(
        DeleteBuildingDocument,
        options
    );
}
export type DeleteBuildingMutationHookResult = ReturnType<typeof useDeleteBuildingMutation>;
export type DeleteBuildingMutationResult = Apollo.MutationResult<Types.DeleteBuildingMutation>;
export type DeleteBuildingMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteBuildingMutation,
    Types.DeleteBuildingMutationVariables
>;
export const SaveApartmentDocument = gql`
    mutation SaveApartment($input: SaveApartmentInput!) {
        saveApartment(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            apartment {
                ...ApartmentFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ApartmentFragmentFragmentDoc}
`;
export type SaveApartmentMutationFn = Apollo.MutationFunction<
    Types.SaveApartmentMutation,
    Types.SaveApartmentMutationVariables
>;

/**
 * __useSaveApartmentMutation__
 *
 * To run a mutation, you first call `useSaveApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApartmentMutation, { data, loading, error }] = useSaveApartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.SaveApartmentMutation, Types.SaveApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveApartmentMutation, Types.SaveApartmentMutationVariables>(
        SaveApartmentDocument,
        options
    );
}
export type SaveApartmentMutationHookResult = ReturnType<typeof useSaveApartmentMutation>;
export type SaveApartmentMutationResult = Apollo.MutationResult<Types.SaveApartmentMutation>;
export type SaveApartmentMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveApartmentMutation,
    Types.SaveApartmentMutationVariables
>;
export const DeleteApartmentDocument = gql`
    mutation DeleteApartment($args: DeleteApartmentInput!) {
        deleteApartment(args: $args) {
            success
        }
    }
`;
export type DeleteApartmentMutationFn = Apollo.MutationFunction<
    Types.DeleteApartmentMutation,
    Types.DeleteApartmentMutationVariables
>;

/**
 * __useDeleteApartmentMutation__
 *
 * To run a mutation, you first call `useDeleteApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApartmentMutation, { data, loading, error }] = useDeleteApartmentMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteApartmentMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.DeleteApartmentMutation, Types.DeleteApartmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteApartmentMutation, Types.DeleteApartmentMutationVariables>(
        DeleteApartmentDocument,
        options
    );
}
export type DeleteApartmentMutationHookResult = ReturnType<typeof useDeleteApartmentMutation>;
export type DeleteApartmentMutationResult = Apollo.MutationResult<Types.DeleteApartmentMutation>;
export type DeleteApartmentMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteApartmentMutation,
    Types.DeleteApartmentMutationVariables
>;
export const SaveExpenseCategoryDocument = gql`
    mutation SaveExpenseCategory($input: SaveExpenseCategoryInput!) {
        saveExpenseCategory(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            expenseCategory {
                ...ExpenseCategoryFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ExpenseCategoryFragmentFragmentDoc}
`;
export type SaveExpenseCategoryMutationFn = Apollo.MutationFunction<
    Types.SaveExpenseCategoryMutation,
    Types.SaveExpenseCategoryMutationVariables
>;

/**
 * __useSaveExpenseCategoryMutation__
 *
 * To run a mutation, you first call `useSaveExpenseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExpenseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExpenseCategoryMutation, { data, loading, error }] = useSaveExpenseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveExpenseCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SaveExpenseCategoryMutation,
        Types.SaveExpenseCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveExpenseCategoryMutation, Types.SaveExpenseCategoryMutationVariables>(
        SaveExpenseCategoryDocument,
        options
    );
}
export type SaveExpenseCategoryMutationHookResult = ReturnType<typeof useSaveExpenseCategoryMutation>;
export type SaveExpenseCategoryMutationResult = Apollo.MutationResult<Types.SaveExpenseCategoryMutation>;
export type SaveExpenseCategoryMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveExpenseCategoryMutation,
    Types.SaveExpenseCategoryMutationVariables
>;
export const DeleteExpenseCategoryDocument = gql`
    mutation DeleteExpenseCategory($args: DeleteExpenseCategoryInput!) {
        deleteExpenseCategory(args: $args) {
            success
        }
    }
`;
export type DeleteExpenseCategoryMutationFn = Apollo.MutationFunction<
    Types.DeleteExpenseCategoryMutation,
    Types.DeleteExpenseCategoryMutationVariables
>;

/**
 * __useDeleteExpenseCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseCategoryMutation, { data, loading, error }] = useDeleteExpenseCategoryMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteExpenseCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteExpenseCategoryMutation,
        Types.DeleteExpenseCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteExpenseCategoryMutation, Types.DeleteExpenseCategoryMutationVariables>(
        DeleteExpenseCategoryDocument,
        options
    );
}
export type DeleteExpenseCategoryMutationHookResult = ReturnType<typeof useDeleteExpenseCategoryMutation>;
export type DeleteExpenseCategoryMutationResult = Apollo.MutationResult<Types.DeleteExpenseCategoryMutation>;
export type DeleteExpenseCategoryMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteExpenseCategoryMutation,
    Types.DeleteExpenseCategoryMutationVariables
>;
export const SaveDefaultCategoryDocument = gql`
    mutation SaveDefaultCategory($input: SaveDefaultCategoryInput!) {
        saveDefaultCategory(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            defaultCategory {
                ...DefaultCategoryFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${DefaultCategoryFragmentFragmentDoc}
`;
export type SaveDefaultCategoryMutationFn = Apollo.MutationFunction<
    Types.SaveDefaultCategoryMutation,
    Types.SaveDefaultCategoryMutationVariables
>;

/**
 * __useSaveDefaultCategoryMutation__
 *
 * To run a mutation, you first call `useSaveDefaultCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDefaultCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDefaultCategoryMutation, { data, loading, error }] = useSaveDefaultCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDefaultCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SaveDefaultCategoryMutation,
        Types.SaveDefaultCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveDefaultCategoryMutation, Types.SaveDefaultCategoryMutationVariables>(
        SaveDefaultCategoryDocument,
        options
    );
}
export type SaveDefaultCategoryMutationHookResult = ReturnType<typeof useSaveDefaultCategoryMutation>;
export type SaveDefaultCategoryMutationResult = Apollo.MutationResult<Types.SaveDefaultCategoryMutation>;
export type SaveDefaultCategoryMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveDefaultCategoryMutation,
    Types.SaveDefaultCategoryMutationVariables
>;
export const DeleteDefaultCategoryDocument = gql`
    mutation DeleteDefaultCategory($args: DeleteDefaultCategoryInput!) {
        deleteDefaultCategory(args: $args) {
            success
        }
    }
`;
export type DeleteDefaultCategoryMutationFn = Apollo.MutationFunction<
    Types.DeleteDefaultCategoryMutation,
    Types.DeleteDefaultCategoryMutationVariables
>;

/**
 * __useDeleteDefaultCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteDefaultCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDefaultCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDefaultCategoryMutation, { data, loading, error }] = useDeleteDefaultCategoryMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteDefaultCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteDefaultCategoryMutation,
        Types.DeleteDefaultCategoryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteDefaultCategoryMutation, Types.DeleteDefaultCategoryMutationVariables>(
        DeleteDefaultCategoryDocument,
        options
    );
}
export type DeleteDefaultCategoryMutationHookResult = ReturnType<typeof useDeleteDefaultCategoryMutation>;
export type DeleteDefaultCategoryMutationResult = Apollo.MutationResult<Types.DeleteDefaultCategoryMutation>;
export type DeleteDefaultCategoryMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteDefaultCategoryMutation,
    Types.DeleteDefaultCategoryMutationVariables
>;
export const SaveApartmentDiscountDocument = gql`
    mutation SaveApartmentDiscount($input: SaveApartmentDiscountInput!) {
        saveApartmentDiscount(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            apartmentDiscount {
                ...ApartmentDiscountFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ApartmentDiscountFragmentFragmentDoc}
`;
export type SaveApartmentDiscountMutationFn = Apollo.MutationFunction<
    Types.SaveApartmentDiscountMutation,
    Types.SaveApartmentDiscountMutationVariables
>;

/**
 * __useSaveApartmentDiscountMutation__
 *
 * To run a mutation, you first call `useSaveApartmentDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApartmentDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApartmentDiscountMutation, { data, loading, error }] = useSaveApartmentDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveApartmentDiscountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SaveApartmentDiscountMutation,
        Types.SaveApartmentDiscountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveApartmentDiscountMutation, Types.SaveApartmentDiscountMutationVariables>(
        SaveApartmentDiscountDocument,
        options
    );
}
export type SaveApartmentDiscountMutationHookResult = ReturnType<typeof useSaveApartmentDiscountMutation>;
export type SaveApartmentDiscountMutationResult = Apollo.MutationResult<Types.SaveApartmentDiscountMutation>;
export type SaveApartmentDiscountMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveApartmentDiscountMutation,
    Types.SaveApartmentDiscountMutationVariables
>;
export const DeleteApartmentDiscountDocument = gql`
    mutation DeleteApartmentDiscount($args: DeleteApartmentDiscountInput!) {
        deleteApartmentDiscount(args: $args) {
            success
        }
    }
`;
export type DeleteApartmentDiscountMutationFn = Apollo.MutationFunction<
    Types.DeleteApartmentDiscountMutation,
    Types.DeleteApartmentDiscountMutationVariables
>;

/**
 * __useDeleteApartmentDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteApartmentDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApartmentDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApartmentDiscountMutation, { data, loading, error }] = useDeleteApartmentDiscountMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteApartmentDiscountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteApartmentDiscountMutation,
        Types.DeleteApartmentDiscountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteApartmentDiscountMutation, Types.DeleteApartmentDiscountMutationVariables>(
        DeleteApartmentDiscountDocument,
        options
    );
}
export type DeleteApartmentDiscountMutationHookResult = ReturnType<typeof useDeleteApartmentDiscountMutation>;
export type DeleteApartmentDiscountMutationResult = Apollo.MutationResult<Types.DeleteApartmentDiscountMutation>;
export type DeleteApartmentDiscountMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteApartmentDiscountMutation,
    Types.DeleteApartmentDiscountMutationVariables
>;
export const SaveApartmentShareDocument = gql`
    mutation SaveApartmentShare($input: SaveApartmentShareInput!) {
        saveApartmentShare(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            apartmentShare {
                ...ApartmentShareFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ApartmentShareFragmentFragmentDoc}
`;
export type SaveApartmentShareMutationFn = Apollo.MutationFunction<
    Types.SaveApartmentShareMutation,
    Types.SaveApartmentShareMutationVariables
>;

/**
 * __useSaveApartmentShareMutation__
 *
 * To run a mutation, you first call `useSaveApartmentShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApartmentShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApartmentShareMutation, { data, loading, error }] = useSaveApartmentShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveApartmentShareMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SaveApartmentShareMutation,
        Types.SaveApartmentShareMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveApartmentShareMutation, Types.SaveApartmentShareMutationVariables>(
        SaveApartmentShareDocument,
        options
    );
}
export type SaveApartmentShareMutationHookResult = ReturnType<typeof useSaveApartmentShareMutation>;
export type SaveApartmentShareMutationResult = Apollo.MutationResult<Types.SaveApartmentShareMutation>;
export type SaveApartmentShareMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveApartmentShareMutation,
    Types.SaveApartmentShareMutationVariables
>;
export const DeleteApartmentShareDocument = gql`
    mutation DeleteApartmentShare($args: DeleteApartmentShareInput!) {
        deleteApartmentShare(args: $args) {
            success
        }
    }
`;
export type DeleteApartmentShareMutationFn = Apollo.MutationFunction<
    Types.DeleteApartmentShareMutation,
    Types.DeleteApartmentShareMutationVariables
>;

/**
 * __useDeleteApartmentShareMutation__
 *
 * To run a mutation, you first call `useDeleteApartmentShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApartmentShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApartmentShareMutation, { data, loading, error }] = useDeleteApartmentShareMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteApartmentShareMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteApartmentShareMutation,
        Types.DeleteApartmentShareMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteApartmentShareMutation, Types.DeleteApartmentShareMutationVariables>(
        DeleteApartmentShareDocument,
        options
    );
}
export type DeleteApartmentShareMutationHookResult = ReturnType<typeof useDeleteApartmentShareMutation>;
export type DeleteApartmentShareMutationResult = Apollo.MutationResult<Types.DeleteApartmentShareMutation>;
export type DeleteApartmentShareMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteApartmentShareMutation,
    Types.DeleteApartmentShareMutationVariables
>;
export const SaveReportDocument = gql`
    mutation SaveReport($input: SaveReportInput!) {
        saveReport(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            report {
                ...ReportFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ReportFragmentFragmentDoc}
`;
export type SaveReportMutationFn = Apollo.MutationFunction<Types.SaveReportMutation, Types.SaveReportMutationVariables>;

/**
 * __useSaveReportMutation__
 *
 * To run a mutation, you first call `useSaveReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveReportMutation, { data, loading, error }] = useSaveReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveReportMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.SaveReportMutation, Types.SaveReportMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveReportMutation, Types.SaveReportMutationVariables>(SaveReportDocument, options);
}
export type SaveReportMutationHookResult = ReturnType<typeof useSaveReportMutation>;
export type SaveReportMutationResult = Apollo.MutationResult<Types.SaveReportMutation>;
export type SaveReportMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveReportMutation,
    Types.SaveReportMutationVariables
>;
export const SaveExpenseDocument = gql`
    mutation SaveExpense($input: SaveExpenseInput!) {
        saveExpense(input: $input) {
            clientMutationId
            errors {
                ...ErrorsFragment
            }
            expense {
                ...ExpenseFragment
            }
        }
    }
    ${ErrorsFragmentFragmentDoc}
    ${ExpenseFragmentFragmentDoc}
`;
export type SaveExpenseMutationFn = Apollo.MutationFunction<
    Types.SaveExpenseMutation,
    Types.SaveExpenseMutationVariables
>;

/**
 * __useSaveExpenseMutation__
 *
 * To run a mutation, you first call `useSaveExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExpenseMutation, { data, loading, error }] = useSaveExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveExpenseMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.SaveExpenseMutation, Types.SaveExpenseMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SaveExpenseMutation, Types.SaveExpenseMutationVariables>(
        SaveExpenseDocument,
        options
    );
}
export type SaveExpenseMutationHookResult = ReturnType<typeof useSaveExpenseMutation>;
export type SaveExpenseMutationResult = Apollo.MutationResult<Types.SaveExpenseMutation>;
export type SaveExpenseMutationOptions = Apollo.BaseMutationOptions<
    Types.SaveExpenseMutation,
    Types.SaveExpenseMutationVariables
>;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($args: DeleteExpenseInput!) {
        deleteExpense(args: $args) {
            success
        }
    }
`;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<
    Types.DeleteExpenseMutation,
    Types.DeleteExpenseMutationVariables
>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteExpenseMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.DeleteExpenseMutation, Types.DeleteExpenseMutationVariables>(
        DeleteExpenseDocument,
        options
    );
}
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<Types.DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteExpenseMutation,
    Types.DeleteExpenseMutationVariables
>;
export const BuildingsDocument = gql`
    query Buildings($searchString: String, $page: Int, $pageSize: Int) {
        buildings(searchString: $searchString, page: $page, pageSize: $pageSize) {
            totalCount
            buildings {
                ...BuildingBasicFragment
            }
        }
    }
    ${BuildingBasicFragmentFragmentDoc}
`;

/**
 * __useBuildingsQuery__
 *
 * To run a query within a React component, call `useBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useBuildingsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.BuildingsQuery, Types.BuildingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BuildingsQuery, Types.BuildingsQueryVariables>(BuildingsDocument, options);
}
export function useBuildingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.BuildingsQuery, Types.BuildingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BuildingsQuery, Types.BuildingsQueryVariables>(BuildingsDocument, options);
}
export type BuildingsQueryHookResult = ReturnType<typeof useBuildingsQuery>;
export type BuildingsLazyQueryHookResult = ReturnType<typeof useBuildingsLazyQuery>;
export type BuildingsQueryResult = Apollo.QueryResult<Types.BuildingsQuery, Types.BuildingsQueryVariables>;
export const BuildingByIdDocument = gql`
    query BuildingById($id: ID!) {
        building(id: $id) {
            ...BuildingExtendedFragment
        }
    }
    ${BuildingExtendedFragmentFragmentDoc}
`;

/**
 * __useBuildingByIdQuery__
 *
 * To run a query within a React component, call `useBuildingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuildingByIdQuery(
    baseOptions: Apollo.QueryHookOptions<Types.BuildingByIdQuery, Types.BuildingByIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BuildingByIdQuery, Types.BuildingByIdQueryVariables>(BuildingByIdDocument, options);
}
export function useBuildingByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.BuildingByIdQuery, Types.BuildingByIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BuildingByIdQuery, Types.BuildingByIdQueryVariables>(
        BuildingByIdDocument,
        options
    );
}
export type BuildingByIdQueryHookResult = ReturnType<typeof useBuildingByIdQuery>;
export type BuildingByIdLazyQueryHookResult = ReturnType<typeof useBuildingByIdLazyQuery>;
export type BuildingByIdQueryResult = Apollo.QueryResult<Types.BuildingByIdQuery, Types.BuildingByIdQueryVariables>;
export const BuildingApartmentsDocument = gql`
    query BuildingApartments($buildingId: ID!) {
        building(id: $buildingId) {
            id
            apartments {
                ...ApartmentFragment
            }
        }
    }
    ${ApartmentFragmentFragmentDoc}
`;

/**
 * __useBuildingApartmentsQuery__
 *
 * To run a query within a React component, call `useBuildingApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingApartmentsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingApartmentsQuery(
    baseOptions: Apollo.QueryHookOptions<Types.BuildingApartmentsQuery, Types.BuildingApartmentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BuildingApartmentsQuery, Types.BuildingApartmentsQueryVariables>(
        BuildingApartmentsDocument,
        options
    );
}
export function useBuildingApartmentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.BuildingApartmentsQuery, Types.BuildingApartmentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BuildingApartmentsQuery, Types.BuildingApartmentsQueryVariables>(
        BuildingApartmentsDocument,
        options
    );
}
export type BuildingApartmentsQueryHookResult = ReturnType<typeof useBuildingApartmentsQuery>;
export type BuildingApartmentsLazyQueryHookResult = ReturnType<typeof useBuildingApartmentsLazyQuery>;
export type BuildingApartmentsQueryResult = Apollo.QueryResult<
    Types.BuildingApartmentsQuery,
    Types.BuildingApartmentsQueryVariables
>;
export const BuildingExpenseCategoriesDocument = gql`
    query BuildingExpenseCategories($buildingId: ID!) {
        building(id: $buildingId) {
            id
            expenseCategories {
                ...ExpenseCategoryFragment
            }
        }
    }
    ${ExpenseCategoryFragmentFragmentDoc}
`;

/**
 * __useBuildingExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useBuildingExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingExpenseCategoriesQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingExpenseCategoriesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.BuildingExpenseCategoriesQuery,
        Types.BuildingExpenseCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BuildingExpenseCategoriesQuery, Types.BuildingExpenseCategoriesQueryVariables>(
        BuildingExpenseCategoriesDocument,
        options
    );
}
export function useBuildingExpenseCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.BuildingExpenseCategoriesQuery,
        Types.BuildingExpenseCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BuildingExpenseCategoriesQuery, Types.BuildingExpenseCategoriesQueryVariables>(
        BuildingExpenseCategoriesDocument,
        options
    );
}
export type BuildingExpenseCategoriesQueryHookResult = ReturnType<typeof useBuildingExpenseCategoriesQuery>;
export type BuildingExpenseCategoriesLazyQueryHookResult = ReturnType<typeof useBuildingExpenseCategoriesLazyQuery>;
export type BuildingExpenseCategoriesQueryResult = Apollo.QueryResult<
    Types.BuildingExpenseCategoriesQuery,
    Types.BuildingExpenseCategoriesQueryVariables
>;
export const ApartmentSharesDocument = gql`
    query ApartmentShares($id: ID!) {
        building(id: $id) {
            id
            apartments {
                id
                code
                shares {
                    id
                    category {
                        id
                        name
                    }
                    value
                }
            }
        }
    }
`;

/**
 * __useApartmentSharesQuery__
 *
 * To run a query within a React component, call `useApartmentSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentSharesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentSharesQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ApartmentSharesQuery, Types.ApartmentSharesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ApartmentSharesQuery, Types.ApartmentSharesQueryVariables>(
        ApartmentSharesDocument,
        options
    );
}
export function useApartmentSharesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ApartmentSharesQuery, Types.ApartmentSharesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ApartmentSharesQuery, Types.ApartmentSharesQueryVariables>(
        ApartmentSharesDocument,
        options
    );
}
export type ApartmentSharesQueryHookResult = ReturnType<typeof useApartmentSharesQuery>;
export type ApartmentSharesLazyQueryHookResult = ReturnType<typeof useApartmentSharesLazyQuery>;
export type ApartmentSharesQueryResult = Apollo.QueryResult<
    Types.ApartmentSharesQuery,
    Types.ApartmentSharesQueryVariables
>;
export const ApartmentDiscountsDocument = gql`
    query ApartmentDiscounts($id: ID!) {
        building(id: $id) {
            id
            apartments {
                id
                code
                discounts {
                    id
                    category {
                        id
                        name
                    }
                    discount
                }
            }
        }
    }
`;

/**
 * __useApartmentDiscountsQuery__
 *
 * To run a query within a React component, call `useApartmentDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentDiscountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApartmentDiscountsQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ApartmentDiscountsQuery, Types.ApartmentDiscountsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ApartmentDiscountsQuery, Types.ApartmentDiscountsQueryVariables>(
        ApartmentDiscountsDocument,
        options
    );
}
export function useApartmentDiscountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ApartmentDiscountsQuery, Types.ApartmentDiscountsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ApartmentDiscountsQuery, Types.ApartmentDiscountsQueryVariables>(
        ApartmentDiscountsDocument,
        options
    );
}
export type ApartmentDiscountsQueryHookResult = ReturnType<typeof useApartmentDiscountsQuery>;
export type ApartmentDiscountsLazyQueryHookResult = ReturnType<typeof useApartmentDiscountsLazyQuery>;
export type ApartmentDiscountsQueryResult = Apollo.QueryResult<
    Types.ApartmentDiscountsQuery,
    Types.ApartmentDiscountsQueryVariables
>;
export const DefaultExpenseCategoriesDocument = gql`
    query DefaultExpenseCategories {
        defaultExpenseCategories {
            ...DefaultCategoryFragment
        }
    }
    ${DefaultCategoryFragmentFragmentDoc}
`;

/**
 * __useDefaultExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useDefaultExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultExpenseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultExpenseCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.DefaultExpenseCategoriesQuery,
        Types.DefaultExpenseCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.DefaultExpenseCategoriesQuery, Types.DefaultExpenseCategoriesQueryVariables>(
        DefaultExpenseCategoriesDocument,
        options
    );
}
export function useDefaultExpenseCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.DefaultExpenseCategoriesQuery,
        Types.DefaultExpenseCategoriesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.DefaultExpenseCategoriesQuery, Types.DefaultExpenseCategoriesQueryVariables>(
        DefaultExpenseCategoriesDocument,
        options
    );
}
export type DefaultExpenseCategoriesQueryHookResult = ReturnType<typeof useDefaultExpenseCategoriesQuery>;
export type DefaultExpenseCategoriesLazyQueryHookResult = ReturnType<typeof useDefaultExpenseCategoriesLazyQuery>;
export type DefaultExpenseCategoriesQueryResult = Apollo.QueryResult<
    Types.DefaultExpenseCategoriesQuery,
    Types.DefaultExpenseCategoriesQueryVariables
>;
export const ReportsDocument = gql`
    query Reports($searchString: String, $page: Int, $pageSize: Int) {
        reports(searchString: $searchString, page: $page, pageSize: $pageSize) {
            totalCount
            reports {
                ...ReportFragment
                building {
                    id
                    code
                }
            }
        }
    }
    ${ReportFragmentFragmentDoc}
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useReportsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.ReportsQuery, Types.ReportsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(ReportsDocument, options);
}
export function useReportsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportsQuery, Types.ReportsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(ReportsDocument, options);
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<Types.ReportsQuery, Types.ReportsQueryVariables>;
export const ReportByIdDocument = gql`
    query ReportById($id: ID!) {
        report(id: $id) {
            ...ReportFragment
        }
    }
    ${ReportFragmentFragmentDoc}
`;

/**
 * __useReportByIdQuery__
 *
 * To run a query within a React component, call `useReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportByIdQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ReportByIdQuery, Types.ReportByIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ReportByIdQuery, Types.ReportByIdQueryVariables>(ReportByIdDocument, options);
}
export function useReportByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportByIdQuery, Types.ReportByIdQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ReportByIdQuery, Types.ReportByIdQueryVariables>(ReportByIdDocument, options);
}
export type ReportByIdQueryHookResult = ReturnType<typeof useReportByIdQuery>;
export type ReportByIdLazyQueryHookResult = ReturnType<typeof useReportByIdLazyQuery>;
export type ReportByIdQueryResult = Apollo.QueryResult<Types.ReportByIdQuery, Types.ReportByIdQueryVariables>;
export const ReportExpensesDocument = gql`
    query ReportExpenses($id: ID!) {
        report(id: $id) {
            expenses {
                ...ExpenseFragment
            }
        }
    }
    ${ExpenseFragmentFragmentDoc}
`;

/**
 * __useReportExpensesQuery__
 *
 * To run a query within a React component, call `useReportExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportExpensesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportExpensesQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ReportExpensesQuery, Types.ReportExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ReportExpensesQuery, Types.ReportExpensesQueryVariables>(
        ReportExpensesDocument,
        options
    );
}
export function useReportExpensesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportExpensesQuery, Types.ReportExpensesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ReportExpensesQuery, Types.ReportExpensesQueryVariables>(
        ReportExpensesDocument,
        options
    );
}
export type ReportExpensesQueryHookResult = ReturnType<typeof useReportExpensesQuery>;
export type ReportExpensesLazyQueryHookResult = ReturnType<typeof useReportExpensesLazyQuery>;
export type ReportExpensesQueryResult = Apollo.QueryResult<
    Types.ReportExpensesQuery,
    Types.ReportExpensesQueryVariables
>;
export const CalculationMethodsDocument = gql`
    query CalculationMethods {
        calculationMethods {
            value
            label
        }
    }
`;

/**
 * __useCalculationMethodsQuery__
 *
 * To run a query within a React component, call `useCalculationMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculationMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculationMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalculationMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.CalculationMethodsQuery, Types.CalculationMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.CalculationMethodsQuery, Types.CalculationMethodsQueryVariables>(
        CalculationMethodsDocument,
        options
    );
}
export function useCalculationMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.CalculationMethodsQuery, Types.CalculationMethodsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.CalculationMethodsQuery, Types.CalculationMethodsQueryVariables>(
        CalculationMethodsDocument,
        options
    );
}
export type CalculationMethodsQueryHookResult = ReturnType<typeof useCalculationMethodsQuery>;
export type CalculationMethodsLazyQueryHookResult = ReturnType<typeof useCalculationMethodsLazyQuery>;
export type CalculationMethodsQueryResult = Apollo.QueryResult<
    Types.CalculationMethodsQuery,
    Types.CalculationMethodsQueryVariables
>;
