import React, { useEffect } from "react";
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { BuildingsDocument, useSaveBuildingMutation } from "graphql/operations";
import { SaveBuildingInput } from "../../graphql/types";
import { toCamelCase } from "../util";

export type BuildingFormData = Omit<SaveBuildingInput, "active" | "clientMutationId">;

type BuildingFormProps = {
    initialValues?: BuildingFormData;
    onFinish?: (building: BuildingFormData) => Promise<void> | void;
};

export const BuildingForm: React.FC<BuildingFormProps> = ({ initialValues, onFinish }) => {
    const [saveMutation, { called, loading, error, data }] = useSaveBuildingMutation({
        refetchQueries: [{ query: BuildingsDocument }],
    });
    const [form] = useForm<BuildingFormData>();

    useEffect(() => {
        if (error) message.error(`Mutation error: ${error}`, 5);
    }, [error]);

    useEffect(() => {
        if (data?.saveBuilding?.errors) {
            form.setFields(
                data?.saveBuilding?.errors.map((err) => ({
                    name: toCamelCase(err?.field!),
                    errors: err?.messages!,
                }))
            );
        }

        if (
            onFinish &&
            called &&
            !loading &&
            data?.saveBuilding?.errors?.length === 0 &&
            data?.saveBuilding?.building
        ) {
            onFinish(data.saveBuilding.building);
        }
    }, [form, onFinish, called, loading, data]);

    useEffect(() => {
        if (initialValues) form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    const rowGutter = 0;
    const collSpan = 6;

    return (
        <Form
            form={form}
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 20 }}
            name="building-form"
            onFinish={(values: BuildingFormData) => {
                if (form.getFieldValue("id")) {
                    saveMutation({ variables: { input: { id: form.getFieldValue("id"), ...values } } });
                } else {
                    saveMutation({ variables: { input: { ...values } } });
                }
            }}
        >
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[{ message: "Building code is required!", required: true }]}
                    >
                        <Input style={{ display: "inline-block" }} tabIndex={1} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item
                        label="Manager's first name"
                        name="managerFirstName"
                        rules={[{ message: "Manager's first name is required!", required: true }]}
                    >
                        <Input placeholder="First name" tabIndex={8} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Price" name="price" rules={[{ message: "Price is required!", required: true }]}>
                        <InputNumber min={0} max={100} step={0.1} tabIndex={15} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item label="Manual" name="isManual" valuePropName="checked">
                        <Checkbox tabIndex={2} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item
                        label="Manager's last name"
                        name="managerLastName"
                        rules={[{ message: "Manager's last name is required!", required: true }]}
                    >
                        <Input placeholder="Last name" tabIndex={9} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Price per apartment" name="isPricePerApartment" valuePropName="checked">
                        <Checkbox tabIndex={16} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="Street"
                        name="street"
                        rules={[{ message: "Street is required!", required: true }]}
                    >
                        <Input tabIndex={3} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="VAT" name="vat">
                        <Input tabIndex={10} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item
                        label="Floor count"
                        name="floorCount"
                        rules={[{ message: "Floor count is required!", required: true }]}
                    >
                        <InputNumber min={0} max={100} step={1} tabIndex={17} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="Street number"
                        name="streetNum"
                        rules={[{ message: "Street number is required!", required: true }]}
                    >
                        <Input tabIndex={4} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Phone" name="phoneHome">
                        <Input placeholder="Home" tabIndex={11} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}></Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[{ message: "City number is required!", required: true }]}
                    >
                        <Input tabIndex={5} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Phone" name="phoneMobile">
                        <Input placeholder="Mobile" tabIndex={12} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}></Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="Post code"
                        name="postalCode"
                        initialValue={initialValues ? initialValues.postalCode : "68100"}
                        rules={[{ message: "Post code is required!", required: true }]}
                    >
                        <Input tabIndex={6} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Phone" name="phoneWork">
                        <Input placeholder="Work" tabIndex={13} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}></Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item
                        label="Region"
                        name="region"
                        rules={[{ message: "Region is required!", required: true }]}
                    >
                        <Input tabIndex={7} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}>
                    <Form.Item label="Email" name="email">
                        <Input tabIndex={14} />
                    </Form.Item>
                </Col>
                <Col span={collSpan}></Col>
            </Row>
            <Row>
                <Col span={collSpan * 1.5}>
                    Notes
                    <Form.Item name="notesVisible">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={collSpan * 1.5}>
                    Hidden notes
                    <Form.Item name="notesInvisible">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={collSpan}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
