import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export const createApolloClient = (token: string | null = null): ApolloClient<NormalizedCacheObject> => {
    const uri = `https://knx-api.fishplant.net/graphql`;

    const httpLink = createHttpLink({ uri });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `JWT ${token}` : "",
            },
        };
    });

    return new ApolloClient<NormalizedCacheObject>({
        link: token ? authLink.concat(httpLink) : httpLink,
        cache: new InMemoryCache(),
    });
};
