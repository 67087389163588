import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { useBuildingApartmentsQuery } from "../../graphql/operations";
import { NewTable } from "../NewTable/NewTable";
import { Apartment, ApartmentRow } from "./ApartmentsRow";

type ApartmentsProps = {
    buildingId: string;
};

export function Apartments({ buildingId }: ApartmentsProps) {
    const { data, loading, error } = useBuildingApartmentsQuery({ variables: { buildingId } });

    useEffect(() => {
        if (error) message.error(`Query error: ${error}`, 5);
    }, [error]);

    if (loading || !data || !data.building?.apartments) return <Spin />;

    return (
        <NewTable<Apartment, { buildingId: string }>
            header={[
                "Code",
                "Lesee first name",
                "Lesee last name",
                "Owner first name",
                "Owner last name",
                "isManager",
                "isClosed",
                "Area",
                "Mills",
                "Floor",
                "ei",
                "fi",
                "Notes",
                "",
            ]}
            dataSource={data.building.apartments}
            RowComponent={ApartmentRow}
            addButtonText="Add apartment"
            saveButtonText="Save apartment changes"
            extraRowProps={{ buildingId }}
        />
    );
}
